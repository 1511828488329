import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { Modal } from '../../components';
import StripeAccountReminder from './StripeAccountReminder';
import css from './ModalMissingInformation.module.css';
import { compose } from 'redux';
import EmailReminder from './EmailReminder';

const MISSING_INFORMATION_MODAL_WHITELIST = [
  'LoginPage',
  'SignupPage',
  'ContactDetailsPage',
  'EmailVerificationPage',
  'PasswordResetPage',
  'StripePayoutPage',
];
const TNC_MODAL_DELAY = 15000;
const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
const STRIPE_ACCOUNT = 'STRIPE_ACCOUNT';
const PROVIDER_PROFILE = 'PROVIDER_PROFILE';
const PROVIDER_TERMS = 'PROVIDER_TERMS';
const WELCOME_MSG = 'WELCOME_MSG';
const START_SUBSCRIPTION = 'START_SUBSCRIPTION';

class ModalMissingInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMissingInformationReminder: '',
      hasSeenMissingInformationReminder: false,
    };
    this.handleMissingInformationReminder = this.handleMissingInformationReminder.bind(this);
  }
  componentDidUpdate() {
    const { currentUser, currentUserHasListings, currentUserHasOrders, location } = this.props;
    const user = ensureCurrentUser(currentUser);
    this.handleMissingInformationReminder(
      user,
      currentUserHasListings,
      currentUserHasOrders,
      location
    );
  }

  handleMissingInformationReminder(
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    newLocation
  ) {
    const routes = routeConfiguration();
    const whitelistedPaths = MISSING_INFORMATION_MODAL_WHITELIST.map(page =>
      pathByRouteName(page, routes)
    );
    // Is the current page whitelisted?
    const isPageWhitelisted = whitelistedPaths.includes(newLocation.pathname);

    // Track if path changes inside Page level component
    const pathChanged = newLocation.pathname !== this.props.location.pathname;
    const notRemindedYet =
      !this.state.showMissingInformationReminder && !this.state.hasSeenMissingInformationReminder;

    // Is the reminder already shown on current page
    const showOnPathChange = notRemindedYet || pathChanged;

    if (!isPageWhitelisted && showOnPathChange) {
      // Emails are sent when order is initiated
      // Customer is likely to get email soon when she books something
      // Provider email should work - she should get an email when someone books a listing
      const hasOrders = currentUserHasOrders === true;
      const hasListingsOrOrders = currentUserHasListings || hasOrders;

      const emailUnverified = !!currentUser.id && !currentUser.attributes.emailVerified;
      const emailVerificationNeeded = emailUnverified;
      const stripeAccountMissing = !!currentUser.id && !currentUser.attributes.stripeConnected;
      const stripeAccountNeeded = currentUserHasListings && stripeAccountMissing;

      const providerProfilePublishMissing =
        !!currentUser.id &&
        !!currentUser?.attributes?.profile?.publicData?.isProvider &&
        !currentUser?.attributes?.profile?.publicData?.isProfilePublished;
      const providerProfilePublishNeeded = providerProfilePublishMissing;

      const providerTermsMissing =
        !!currentUser.id &&
        !!currentUser.attributes?.profile?.publicData?.isProvider &&
        !(
          currentUser.attributes?.profile?.publicData?.ownPolicy ||
          currentUser.attributes?.profile?.publicData?.tncs?.length > 0 ||
          currentUser.attributes?.profile?.publicData?.refundPolicy?.info
        );
      const providerTermsNeeded = providerTermsMissing;
      const welcomeMessage =
        !!currentUser.id &&
        !!currentUser.attributes?.profile?.publicData?.isProvider &&
        !!currentUser.attributes?.profile?.publicData?.showWelcomeMsg;

      const showStartSubscriptionModal =
        !!currentUser.id &&
        !!currentUser.attributes?.profile?.publicData?.isProvider &&
        !currentUser.attributes?.profile?.privateData?.subscription?.subscriptionId;
      // Show reminder
      if (emailVerificationNeeded) {
        this.setState({ showMissingInformationReminder: EMAIL_VERIFICATION });
      } else if (welcomeMessage) {
        this.setState({ showMissingInformationReminder: WELCOME_MSG });
      } else if (false) {
        // } else if (stripeAccountNeeded) {
        this.setState({ showMissingInformationReminder: STRIPE_ACCOUNT });
      } else if (providerProfilePublishNeeded) {
        this.setState({ showMissingInformationReminder: PROVIDER_PROFILE });
      } else if (providerTermsNeeded) {
        // const {}
        const shouldDelay =
          this.props.location?.pathname?.includes('/u/') &&
          this.props.location?.pathname?.includes(this.props.currentUser?.id?.uuid) &&
          this.props.currentUser?.attributes?.profile?.publicData?.isProvider;
        if (shouldDelay) {
          setTimeout(() => {
            this.setState({ showMissingInformationReminder: PROVIDER_TERMS });
          }, TNC_MODAL_DELAY);
        } else {
          this.setState({ showMissingInformationReminder: PROVIDER_TERMS });
        }
      } else if (showStartSubscriptionModal) {
        this.setState({ showMissingInformationReminder: START_SUBSCRIPTION });
      }
    }
  }

  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      currentUser,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onManageDisableScrolling,
      onResendVerificationEmail,
      history,
    } = this.props;
    const user = ensureCurrentUser(currentUser);
    const classes = classNames(rootClassName || css.root, className);

    let content = null;
    const currentUserLoaded = user && user.id;

    if (currentUserLoaded) {
      if (this.state.showMissingInformationReminder === EMAIL_VERIFICATION) {
        content = (
          <EmailReminder
            className={classes}
            user={user}
            onResendVerificationEmail={onResendVerificationEmail}
            sendVerificationEmailInProgress={sendVerificationEmailInProgress}
            sendVerificationEmailError={sendVerificationEmailError}
          />
        );
      } else if (this.state.showMissingInformationReminder === WELCOME_MSG) {
        content = (
          <div className={css.welcomeMessage}>
            <h3>Congratulations</h3>
            <p>
              <span className={css.redspn}>1-MONTH</span> free trial starts when you add your 1
              <sup>st</sup> activity.
            </p>
            <span>To get best from Activity Time today, add your...</span>
            <ul>
              <li>
                <p>Profile</p>
              </li>
              <li>
                <p>T&Cs</p>
              </li>
              <li>
                <p>Activities</p>
              </li>
            </ul>
            <span>We welcome you to add as many activities as you want.</span>
            <button
              onClick={() =>
                this.setState(
                  {
                    showMissingInformationReminder: null,
                    hasSeenMissingInformationReminder: true,
                  },
                  () =>
                    history.push(
                      createResourceLocatorString(
                        'ProfileSettingsBasePage',
                        routeConfiguration(),
                        {},
                        {}
                      )
                    )
                )
              }
            >
              Next
            </button>
          </div>
        );
      } else if (this.state.showMissingInformationReminder === STRIPE_ACCOUNT) {
        content = <StripeAccountReminder className={classes} />;
        // } else if (true) {
      } else if (this.state.showMissingInformationReminder === PROVIDER_PROFILE) {
        content = (
          <div className={css.cstmmsg}>
            <p>
              Please complete <span>Build My Profile</span> to unlock the full potential of Activity
              Time.Your future listings, reviews, rankings and visibility all depend on a complete
              profile.
            </p>
            <p>
              Let’s make sure customers know who you are, so they can book your activities with
              confidence.
            </p>
            <button
              type="button"
              onClick={() => {
                this.setState(
                  {
                    showMissingInformationReminder: null,
                    hasSeenMissingInformationReminder: true,
                  },
                  () => {
                    history.push(
                      createResourceLocatorString(
                        'ProfileSettingsBasePage',
                        routeConfiguration(),
                        {},
                        {}
                      )
                    );
                  }
                );
              }}
            >
              Build My Profile
            </button>
          </div>
        );
      } else if (this.state.showMissingInformationReminder === PROVIDER_TERMS) {
        content = (
          <div className={css.cstmmsg}>
            <p>
              We invite you to <span>Add T&C's</span> next.
            </p>
            <p>
              Having clear terms and conditions is good <br />
              for both you and your customer.
            </p>
            <p>
              Add your refund policy, waivers and <br />
              all other essentials, in just 2 steps.
            </p>
            <button
              type="button"
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'TermsAndConditionsPage',
                    routeConfiguration(),
                    {},
                    {}
                  )
                );
              }}
            >
              T&C's
            </button>
          </div>
        );
      } else if (this.state.showMissingInformationReminder === START_SUBSCRIPTION) {
        content = (
          <div className={css.cstmmsg}>
            <p>
              <center>
                <b>
                  <span className={css.redspn}>1-MONTH</span> free trial starts when <br /> you add
                  your 1<sup>st</sup> activity.
                </b>
              </center>
            </p>
            <p>
              The Activity Time Team is excited to support <br /> you easily reach a wider audience
              and <br />
              sell amazing activities, smarter.
            </p>
            <p>
              What You Need: 3 - 5 photos,
              <br /> activity description and just 3 minutes.
              <br /> Hmm, maybe allow 10 minutes for the first listing.
            </p>
            <p>
              <center>
                <b>Add as many listings as you want!</b>
              </center>
            </p>
            <button
              type="button"
              onClick={() => {
                this.setState(
                  {
                    showMissingInformationReminder: null,
                    hasSeenMissingInformationReminder: true,
                  },
                  () => {
                    history.push(
                      createResourceLocatorString('CreateListingPage', routeConfiguration(), {}, {})
                    );
                  }
                );
              }}
            >
              Start Free Trial: Add 1<sup>st</sup> Activity
            </button>
          </div>
        );
      }
    }

    const closeButtonMessage = (
      <FormattedMessage id="ModalMissingInformation.closeVerifyEmailReminder" />
    );

    return (
      <Modal
        id="MissingInformationReminder"
        // containerClassName={containerClassName}
        // isOpen={!!true}
        isOpen={!!this.state.showMissingInformationReminder}
        onClose={() => {
          this.setState(state => ({
            showMissingInformationReminder:
              state.showMissingInformationReminder === EMAIL_VERIFICATION &&
              user?.attributes?.profile?.publicData?.isProvider &&
              user?.attributes?.profile?.publicData?.showWelcomeMsg !== false
                ? WELCOME_MSG
                : null,
            hasSeenMissingInformationReminder: true,
          }));
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
      >
        {content}
      </Modal>
    );
  }
}

ModalMissingInformation.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

ModalMissingInformation.propTypes = {
  id: string.isRequired,
  className: string,
  rootClassName: string,
  containerClassName: string,

  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool.isRequired,
};

ModalMissingInformation.displayName = 'ModalMissingInformation';

export default ModalMissingInformation;
