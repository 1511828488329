import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import saveTime from '../../assets/save-time-icon.png';
import feelOrganised from '../../assets/feel-organised-icon.png';
import happyKids from '../../assets/happy-kids-icon.png';
import afterSchoolClubs from './../../assets/Section discover/afterSchoolClubs.jpg';
import birthdays from '../../assets/Section discover/birthdays.jpg';
import breakfastClubs from '../../assets/Section discover/breakfastClubs.jpg';
import camps from '../../assets/Section discover/camps.jpg';
import classesImg from '../../assets/Section discover/classes.jpg';
import clubs from '../../assets/Section discover/clubs.jpg';
import events from '../../assets/Section discover/events.jpg';
import inSchoolPrograms from '../../assets/Section discover/inSchoolPrograms.jpg';
import schoolTours from '../../assets/Section discover/schoolTours.jpg';
import workshops from '../../assets/Section discover/workshops.jpg';
import placestovisit from '../../assets/Section discover/PlacesToVisit.jpg';
import { NamedLink } from '../../components';
import Slider from 'react-slick';

import css from './SectionLocations.module.css';

import SectionLocationImageLMB from './../../assets/lmbsecloc.png';
import SectionLocationImage from './../../assets/SectionLocationImage.jpg';
class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};
var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,

  // responsive: [
  //   {
  //     breakpoint: 1024,
  //     settings: {
  //       slidesToShow: 3,
  //       slidesToScroll: 3,
  //       infinite: true,
  //       dots: true,
  //     },
  //   },
  // ],
};
const SectionLocations = props => {
  const { rootClassName, className, location, width, renderProviderLandingContent } = props;
  const classes = classNames(rootClassName || css.root, className);
  const cats = [
    { img: classesImg, title: 'Classes' },
    { img: camps, title: 'Camps' },
    { img: workshops, title: 'Workshops' },
    { img: events, title: 'Events' },
    { img: schoolTours, title: 'School Tours' },
    { img: breakfastClubs, title: 'Breakfast & After-School Club' },
    { img: placestovisit, title: 'Places to Visit' },
    { img: clubs, title: 'Clubs' },
    { img: birthdays, title: 'Parties' },
    { img: inSchoolPrograms, title: 'In-School Programs' },
  ];
  return (
    <div className={`${classes}`}>
      <div className={css.sectionIcons}>
        <div className={css.iconContainer}>
          <img src={saveTime} alt="jv" className={css.iconImg} />
          <FormattedMessage id="SectionLocations.saveTime" />
        </div>
        <div className={css.iconContainer}>
          <img src={feelOrganised} alt="jv" className={css.iconImg} />
          <FormattedMessage id="SectionLocations.feelOrganised" />
        </div>
        <div className={css.iconContainer}>
          <img src={happyKids} alt="jv" className={css.iconImg} />
          <FormattedMessage id="SectionLocations.happyKids" />
        </div>
      </div>
      <div className={css.title} id="section-who-we-serve">
        {renderProviderLandingContent ? (
          <span>
            <FormattedMessage id="SectionLocations.listMyBusinessTitle" />
          </span>
        ) : (
          <FormattedMessage id="SectionLocations.title" />
        )}
      </div>
      {/* <div className={css.youNoLonger}></div> */}
      {renderProviderLandingContent ? (
        <>
          {/* <p className={classNames(css.youNoLonger, css.extraContentStyle)}>
            Activity providers, you deserve easier.We’ve designed one tool to reach new families and
            schools, secure bookings and breeze through admin all in one place.
          </p> */}
          <p className={css.midp}>For activity providers who want to save time and money.</p>
          <p className={classNames(css.youNoLonger, css.extraContentStyle)}>
            Finally, one tool that brings customers to you, offers flexible one-click bookings and
            makes your admin a breeze - all in one trusted place!
          </p>
        </>
      ) : (
        <div className={css.points}>
          <p className={`${css.redp} ${css.youNoLonger}`}>
            With Activity Time,
            <span className={css.italicEndTag}> you can:</span>
          </p>
          <div className={css.bulletLists}>
            <ul>
              <li>Avoid bored and disappointed kids</li>
              <li>Save time with quick, easy searches</li>
              <li>Stay organised effortlessly</li>
              <li>Compare and save money on the best activities</li>
            </ul>
          </div>
          <p className={`${css.keepkds} ${css.youNoLonger}`}>
            Keep your kids engaged and happy with minimal effort!
          </p>
        </div>
      )}
      {renderProviderLandingContent && (
        <div className={css.discoverImages}>
          {cats.map(d => (
            <button key={d.title} className={css.imageButtons}>
              <img src={d.img} className={css.images} />
              <span>{d.title}</span>
            </button>
          ))}
        </div>
      )}
      {!renderProviderLandingContent && (
        <div className={css.sectionLocationCard}>
          <div className={css.sectionLocationCardText}>
            <p className={css.cardHeading}>
              Everything You Need To Be
              <span className={css.inspired}> Inspired </span>
              <span className={css.inspired}> & </span>
              <span className={css.organised}>Organised</span> For Your Kids.
            </p>
            <p className={css.cartxtsm}>
              The biggest variety of kids activities in Ireland at your fingertips...
            </p>
          </div>
          <div>
            {/* for list my business this should be video */}
            <img src={SectionLocationImage} className={css.sectionLocationCardImage} />
          </div>
        </div>
      )}
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
