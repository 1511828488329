import React, { Component, useEffect, useRef, useState } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import moment from 'moment';
import { AiOutlineHeart, AiFillStar, AiOutlineStar } from 'react-icons/ai';

import css from './ProviderListingCard.module.css';
import { createInstance } from '../../util/sdkLoader';
import { set } from 'lodash';
import { placesToVisitActivityTypeOptions } from '../../assets/activityTypeOptions';
import StarRating from '../StarRating/StarRating';
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ProviderListingCard = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
  } = props;

  const sdkInstance = useRef(createInstance({ clientId: config.sdk.clientId }));

  const [listingsDate, setListingsDate] = useState({ sDate: '', eDate: '' });

  const [listingsTime, setListingsTime] = useState({ sTime: '', eTime: '' });

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  // const provider=
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const priceTypeTexts =
    publicData?.priceType === 'perHour'
      ? 'per hour'
      : publicData?.priceType === 'perChild'
      ? 'per child'
      : publicData?.priceType === 'totalPrice'
      ? 'total price'
      : publicData?.priceType === 'perMorning'
      ? 'per morning'
      : publicData?.priceType === 'perAfternoon'
      ? 'per afternoon'
      : publicData?.priceType === 'perDay'
      ? 'per day'
      : publicData?.priceType === 'perTerm'
      ? 'per term'
      : publicData?.priceType === 'perClass'
      ? 'per class'
      : publicData?.priceType === 'perYear'
      ? 'per year'
      : publicData?.priceType === 'perMonth'
      ? 'per month'
      : publicData?.priceType === 'perAdult'
      ? 'per adult'
      : null;
  const ProviderPublicdata = listing?.author?.attributes?.profile?.publicData;

  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const startDay = moment(listingsDate?.sDate);
  const endDay = moment(listingsDate?.eDate);
  const {
    categories,
    startDate,
    endDate,
    startTime,
    endTime,
    noOfDays,
    openTill,
    noOfKids,
    noOfClasses,
    closingTime,
  } = publicData;
  const activityTypesObj = publicData?.activityType?.map(a =>
    placesToVisitActivityTypeOptions.find(o => o.value === a)
  );
  const listingInfos =
    publicData?.categories?.[0] === 'events' ? (
      <>
        <div className={css.datetimeinfo}>
          <div className={css.datetimeinfocol}>
            <span>Age:</span>{' '}
            {(publicData?.categories?.[0] === 'events' ||
              publicData?.categories?.[0] === 'placesToVisit') &&
            publicData?.allAges
              ? 'All ages'
              : `${publicData?.age?.[0]} - ${publicData?.age?.[publicData?.age?.length - 1]}`}
          </div>
          <div className={css.datetimeinfocol}>
            <span>Date:</span> {moment(startDate).format('MMM Do, YYYY')}{' '}
            {publicData?.day?.length >= 0
              ? `(${publicData?.day?.length} ${publicData?.day?.length > 1 ? 'days' : 'day'})`
              : null}
          </div>
          <div className={css.datetimeinfocol}>
            {publicData?.day?.map((d, i) => (
              <div key={i}>
                <span>Time (day {i + 1}): </span>{' '}
                {d.startTime?.hour >= 10 ? d.startTime?.hour : `0${d.startTime?.hour}`}.
                {d.startTime?.minute >= 10 ? d.startTime?.minute : `0${d.startTime?.minute}`} –{' '}
                {d.endTime?.hour >= 10 ? d.endTime?.hour : `0${d.endTime?.hour}`}.
                {d.endTime?.minute >= 10 ? d.endTime?.minute : `0${d.endTime?.minute}`}
              </div>
            ))}
          </div>
          {publicData?.city || publicData?.county ? (
            <div className={css.datetimeinfocol}>
              <span>Location:</span>{' '}
              {publicData?.city
                ? publicData?.city?.toUpperCase()
                : publicData?.county
                ? publicData?.county?.toUpperCase()
                : ''}{' '}
              {price?.amount === 0 ? '' : priceTypeTexts}
            </div>
          ) : null}
          <div className={css.datetimeinfocol}>
            <span>Cost:</span> {price.amount === 0 ? 'FREE' : formattedPrice}{' '}
            {price?.amount === 0 ? '' : priceTypeTexts}
          </div>
        </div>
      </>
    ) : publicData?.categories?.[0] === 'club' ? (
      <>
        <div className={css.datetimeinfo}>
          <div className={css.datetimeinfocol}>
            <span>Age:</span>{' '}
            {(publicData?.categories?.[0] === 'events' ||
              publicData?.categories?.[0] === 'placesToVisit') &&
            publicData?.allAges
              ? 'All ages'
              : `${publicData?.age?.[0]} - ${publicData?.age?.[publicData?.age?.length - 1]}`}
          </div>

          <div className={css.datetimeinfocol}>
            <span>Cost:</span> {price.amount === 0 ? 'FREE' : formattedPrice}{' '}
            {price?.amount === 0 ? '' : priceTypeTexts}
          </div>
        </div>
      </>
    ) : publicData?.categories?.[0] === 'placesToVisit' ? (
      <>
        <div className={css.datetimeinfo}>
          <div className={css.datetimeinfocol}>
            <span>Age:</span>{' '}
            {(publicData?.categories?.[0] === 'events' ||
              publicData?.categories?.[0] === 'placesToVisit') &&
            publicData?.allAges
              ? 'All ages'
              : `${publicData?.age?.[0]} - ${publicData?.age?.[publicData?.age?.length - 1]}`}
          </div>
          <div className={css.datetimeinfocol}>
            <span>Categories:</span>{' '}
            {activityTypesObj &&
              publicData?.categories[0] === 'placesToVisit' &&
              activityTypesObj.reduce(
                (pre, curr, idx) =>
                  `${pre}${
                    idx === 0
                      ? ` ${curr.label}`
                      : idx === activityTypesObj?.length - 1
                      ? ` & ${curr.label}`
                      : `, ${curr.label}`
                  }`,
                ''
              )}
          </div>

          <div className={css.datetimeinfocol}>
            <span>Cost:</span> {price.amount === 0 ? 'FREE' : formattedPrice}{' '}
            {price?.amount === 0 ? '' : priceTypeTexts}
          </div>
        </div>
      </>
    ) : publicData?.categories?.[0] === 'afterSchoolClub' ||
      publicData?.categories?.[0] === 'breakfastClub' ? (
      <>
        <div className={css.datetimeinfo}>
          <div className={css.datetimeinfocol}>
            <span>Age:</span>{' '}
            {(publicData?.categories?.[0] === 'events' ||
              publicData?.categories?.[0] === 'placesToVisit') &&
            publicData?.allAges
              ? 'All ages'
              : `${publicData?.age?.[0]} - ${publicData?.age?.[publicData?.age?.length - 1]}`}
          </div>
          <div className={css.datetimeinfocol}>
            <span>Opens:</span> {openTill?.hour < 10 ? `0${openTill?.hour}` : openTill?.hour}.
            {openTill?.minute < 10 ? `0${openTill?.minute}` : openTill?.minute} hours
          </div>
          <div className={css.datetimeinfocol}>
            <span>Opens:</span>{' '}
            {closingTime?.hour < 10 ? `0${closingTime?.hour}` : closingTime?.hour}.
            {closingTime?.minute < 10 ? `0${closingTime?.minute}` : closingTime?.minute} hours
          </div>
          <div className={css.datetimeinfocol}>
            <span>Cost:</span> {price.amount === 0 ? 'FREE' : formattedPrice}{' '}
            {price?.amount === 0 ? '' : priceTypeTexts}
          </div>
        </div>
      </>
    ) : publicData?.categories?.[0] === 'schoolTours' ||
      publicData?.categories?.[0] === 'birthdayParty' ||
      publicData?.categories?.[0] === 'inSchoolProgrammes' ? (
      <>
        <div className={css.datetimeinfo}>
          <div className={css.datetimeinfocol}>
            <span>Age:</span>{' '}
            {(publicData?.categories?.[0] === 'events' ||
              publicData?.categories?.[0] === 'placesToVisit') &&
            publicData?.allAges
              ? 'All ages'
              : `${publicData?.age?.[0]} - ${publicData?.age?.[publicData?.age?.length - 1]}`}
          </div>
          <div className={css.datetimeinfocol}>
            <span>
              {publicData?.categories?.[0] === 'inSchoolProgrammes' ||
              publicData?.categories?.[0] === 'birthdayParty'
                ? 'Time:'
                : 'Length:'}
            </span>{' '}
            {openTill?.hour < 10 ? `0${openTill?.hour}` : openTill?.hour}.
            {openTill?.minute < 10 ? `0${openTill?.minute}` : openTill?.minute} hours
          </div>
          <div className={css.datetimeinfocol}>
            <span>Min - Max:</span> {noOfKids?.minNo} - {noOfKids?.maxNo}
          </div>
          <div className={css.datetimeinfocol}>
            <span>Cost:</span> {price.amount === 0 ? 'FREE' : formattedPrice}{' '}
            {price?.amount === 0 ? '' : priceTypeTexts}
          </div>
        </div>
      </>
    ) : publicData?.categories?.[0] === 'camps' || publicData?.categories?.[0] === 'classes' ? (
      <>
        <div className={css.datetimeinfo}>
          <div className={css.datetimeinfocol}>
            <span>Date:</span>{' '}
            {`${moment(startDate).format('MMM Do')} – ${moment(endDate).format('MMM Do, YYYY')}`}
          </div>
          <div className={css.datetimeinfocol}>
            <span>Time:</span> {startTime?.hour >= 10 ? startTime?.hour : `0${startTime?.hour}`}.
            {startTime?.minute >= 10 ? startTime?.minute : `0${startTime?.minute}`} –{' '}
            {endTime?.hour >= 10 ? endTime?.hour : `0${endTime?.hour}`}.
            {endTime?.minute >= 10 ? endTime?.minute : `0${endTime?.minute}`}
          </div>
          <div className={css.datetimeinfocol}>
            <span>Age:</span>{' '}
            {(publicData?.categories?.[0] === 'events' ||
              publicData?.categories?.[0] === 'placesToVisit') &&
            publicData?.allAges
              ? 'All ages'
              : `${publicData?.age?.[0]} - ${publicData?.age?.[publicData?.age?.length - 1]}`}
          </div>
          <div className={css.datetimeinfocol}>
            <span>Cost:</span> {price.amount === 0 ? 'FREE' : formattedPrice}{' '}
            {noOfDays && publicData?.categories?.[0] === 'camps'
              ? `(${noOfDays} ${noOfDays > 1 ? 'days' : 'day'})`
              : null}
            {noOfClasses && publicData?.categories?.[0] === 'classes'
              ? `(${noOfClasses} ${noOfClasses > 1 ? 'classes' : 'class'})`
              : null}
          </div>
        </div>
      </>
    ) : (
      <>
        <div className={css.datetimeinfo}>
          <div className={css.datetimeinfocol}>
            <span>Date:</span>{' '}
            {categories[0] === 'classes' ||
            categories[0] === 'events' ||
            categories[0] === 'workshops'
              ? moment(startDate).format('MMM Do, YYYY')
              : `${moment(startDate).format('MMM Do')} – ${moment(endDate).format('MMM Do, YYYY')}`}
          </div>
          <div className={css.datetimeinfocol}>
            <span>Time:</span> {startTime?.hour >= 10 ? startTime?.hour : `0${startTime?.hour}`}.
            {startTime?.minute >= 10 ? startTime?.minute : `0${startTime?.minute}`} –{' '}
            {endTime?.hour >= 10 ? endTime?.hour : `0${endTime?.hour}`}.
            {endTime?.minute >= 10 ? endTime?.minute : `0${endTime?.minute}`}
          </div>
          <div className={css.datetimeinfocol}>
            <span>Age:</span>{' '}
            {(publicData?.categories?.[0] === 'events' ||
              publicData?.categories?.[0] === 'placesToVisit') &&
            publicData?.allAges
              ? 'All ages'
              : `${publicData?.age?.[0]} - ${publicData?.age?.[publicData?.age?.length - 1]}`}
          </div>
          <div className={css.datetimeinfocol}>
            <span>Cost:</span> {price.amount === 0 ? 'FREE' : formattedPrice}{' '}
            {categories[0] === 'workshops' ? '(1 day)' : null}
            {price?.amount === 0 ? '' : priceTypeTexts}
          </div>
        </div>
      </>
    );
  const seats = currentListing?.currentStock?.attributes?.quantity;
  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <div className={css.htisgn}>
            <AiOutlineHeart />
          </div>
          <div
            className={classNames(css.spdv, {
              // [css.dispn]: isMobileModalOpen,
            })}
          >
            {publicData?.categories[0] === 'afterSchoolClub' ||
            publicData?.categories[0] === 'birthdayParty' ||
            publicData?.categories[0] === 'breakfastClub' ||
            publicData?.categories[0] === 'club' ||
            publicData?.categories[0] === 'events' ||
            publicData?.categories[0] === 'inSchoolProgrammes' ||
            publicData?.categories[0] === 'placesToVisit' ||
            publicData?.categories[0] === 'schoolTours' ? (
              publicData?.bookingType === 'offPlatform' ? (
                <div className={css.spcspn}>
                  <span>Off-Platform</span>
                </div>
              ) : (
                <div className={css.spcspn}>
                  <span>Contact to Book</span>
                </div>
              )
            ) : null}
            {publicData?.categories[0] === 'camps' ||
            publicData?.categories[0] === 'classes' ||
            publicData?.categories[0] === 'workshops' ? (
              publicData?.bookingType === 'offPlatform' ? (
                <div className={css.spcspn}>
                  <span>Off-Platform</span>
                </div>
              ) : seats ? (
                <div className={css.spcspn}>
                  <span>Spaces: </span>
                  {seats > 25 ? '25+' : seats}
                </div>
              ) : publicData?.waitingList ? (
                <div className={css.spcspn}>
                  <span>+ Waiting List</span>
                </div>
              ) : (
                <div className={css.spcspn}>
                  <span>Contact to Book</span>
                </div>
              )
            ) : null}
          </div>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />

          <div className={css.ratingstar}>
            <StarRating rating={Number(ProviderPublicdata?.avgRating || 0)} />
          </div>
        </div>
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.addinfo}>
            {' '}
            {ProviderPublicdata?.bname} -{' '}
            <span>
              {publicData?.city
                ? publicData?.city?.toUpperCase()
                : publicData?.county?.toUpperCase() && publicData?.county?.toUpperCase()}
            </span>
          </div>

          <div className={css.certificateInfo}>
            {certificate && !certificate.hideFromListingInfo ? (
              <span>{certificate.label}</span>
            ) : null}
          </div>
        </div>
        {listingInfos}
      </div>
    </NamedLink>
  );
};

ProviderListingCard.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ProviderListingCard.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ProviderListingCard);
